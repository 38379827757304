<template>
  <div class="gps-car">
    <view-grid
      :tableCols="tableCols"
      :tableOps="tableOps"
      :query="query"
      :tableData="tableData"
      :total="total"
      :searchCols="searchCols"
      @callbackEvent="callbackEvent"
      @handleCurrentChange="handleCurrentChange"
      :tabsLabel="tabsLabel"
      :tableDetailCols="tableDetailCols"
      :queryDetail="queryDetail"
      :tableDetailData="tableDetailData"
      :totalDetail="totalDetail"
      @handleCurrentChangeDetail="handleCurrentChangeDetail"
      :tabsName="tabsName"
      @tabsActiveNameChange="tabsActiveNameChange"
    ></view-grid>
    <GpsFilesView
      :isShow="isFileShow"
      @ErpDialogEvent="ErpDialogEvent"
      :row="row_detail"
    ></GpsFilesView>
  </div>
</template>

<script setup>
import ViewGrid from "./components/ViewGrid.vue";
import GpsFilesView from "../../components/GpsFilesView.vue";
import {
  ref,
  defineAsyncComponent,
  watch,
  getCurrentInstance,
  shallowRef,
  reactive,
  onMounted,
  // markRaw,
  // toRef,
  inject,
  toRaw,
} from "vue";
// import {
//   Plus,
//   Delete,
//   BottomLeft,
//   TopRight,
//   Search,
//   Download,
//   Edit,
// } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n";
import Tools from "./ZdaqbjData";
import { ElNotification } from "element-plus";
import moment from "moment";
const { t } = useI18n();
const refAnyCom = ref();
let { proxy } = getCurrentInstance();

const isFileShow = ref(false);
const row_detail = ref({});

//表格显示的列
const tableCols = ref(Tools.tableCols);
//表格操作列
const tableOps = ref(Tools.tableOps);
//表单操作
const formArray = reactive(Tools.formCols);
const row = {};
const curr_row = reactive(row);

const total = ref(0);

const handleSizeChange = (val) => {
  console.log(`${val} items per page`);
};
const handleCurrentChange = (val) => {
  query.page = val;
  getList();
};

//查询列
const searchCols = ref(Tools.searchCols);

const tableData = ref([]);
const query = reactive({
  rp: 25,
  page: 1,
  devids: "",
  begintime: "",
  endtime: "",
});

const delChannel = (dataList) => {
  dataList.forEach((it) => {
    if (it.nodetype == 2) {
      it.children = [];
    } else {
      if (it.children && it.children.length > 0) {
        delChannel(it.children);
      }
    }
  });
};

const getList = () => {
  proxy.$api.search_zdaqbj_grid(query).then((res) => {
    tableData.value = res.data.data;
    total.value = res.data.pagination.totalRecords;
  });
};
const init = () => {
  proxy.$api.tree_deptTree_Tree({}).then((res) => {
    // searchCols.value[0].data = res.data.resultData;
    let dataTree = res.data.resultData;
    delChannel(dataTree);
    searchCols.value[0].data = dataTree;
  });
};

let callbackFun;
let treeRef;

onMounted(() => {
  init();
  const PARENT_PROVIDE = "REPORT_EVENT";
  callbackFun = inject(PARENT_PROVIDE);
  treeRef = inject("treeRef");
});

const isCrossMonth = (dateStr1, dateStr2) => {
  // 将时间字符串解析为 moment 对象
  const moment1 = moment(dateStr1);
  const moment2 = moment(dateStr2);

  // 获取年份和月份
  const year1 = moment1.year();
  const month1 = moment1.month(); // 注意：月份从0开始（0=1月，11=12月）

  const year2 = moment2.year();
  const month2 = moment2.month();

  // 判断是否跨月
  return year1 !== year2 || month1 !== month2;
};

const callbackEvent = (obj) => {
  let ay_dev = [];
  switch (obj.type) {
    case "change_time":
      console.log(obj);
      if (obj.evt == 1) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment().format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment().format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 2) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment().subtract(1, "days").format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment().subtract(1, "days").format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 3) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment().startOf("week").format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment().endOf("week").format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 4) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment()
              .subtract(1, "weeks")
              .startOf("week")
              .format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment()
              .subtract(1, "weeks")
              .endOf("week")
              .format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 5) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment().startOf("month").format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment().endOf("month").format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 6) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment()
              .subtract(1, "months")
              .startOf("month")
              .format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment()
              .subtract(1, "months")
              .endOf("month")
              .format("YYYY-MM-DD 23:59:59");
          }
        });
      } else if (obj.evt == 7) {
        searchCols.value.forEach((it) => {
          if (it.key == "begintime") {
            it.val = moment().format("YYYY-MM-DD 00:00:00");
          } else if (it.key == "endtime") {
            it.val = moment().format("YYYY-MM-DD 23:59:59");
          }
        });
      }
      break;
    case "view_files":
      row_detail.value = obj.row;
      isFileShow.value = true;
      break;
    case "get_addr":
      proxy.$api
        .bdmap_get_gps_addr({
          lng: parseFloat(obj.lng),
          lat: parseFloat(obj.lat),
        })
        .then((res_addr) => {
          obj.row[obj.fd] = res_addr.data.formatted_address;
        });
      break;
    case "detail":
      console.log(obj.row);
      queryDetail.devids = "";
      queryDetail.devids = obj.row.devid;
      queryDetail.begintime = query.begintime;
      queryDetail.endtime = query.endtime;
      tabsName.value = "detail";
      getDetail();
      break;
    case "search":
      query.devids = "";
      // let ay = toRaw(treeRef.value.getCheckedNodes());
      // let ay_dev = [];
      // ay.forEach((it) => {
      //   if (it.nodetype == 2) {
      //     ay_dev.push(it.vDeviceID);
      //   }
      // });
      // query.devids = ay_dev.join(",");
      // console.log(obj.search);
      // let ay_time = obj.search.times.split(",");
      // if (ay_time.length == 2) {
      //   query.begintime = ay_time[0];
      //   query.endtime = ay_time[1];
      // }

      query.begintime = obj.search.begintime;
      query.endtime = obj.search.endtime;

      if (
        isCrossMonth(
          query.begintime.replace(" ", "T"),
          query.endtime.replace(" ", "T")
        )
      ) {
        ElMessageBox.alert(t("map.qbykycx"));
        return;
      }

      ay_dev = [];
      obj.search.devids.forEach((it) => {
        if (it.nodetype == 2) {
          ay_dev.push(it.vDeviceID);
        }
      });
      query.devids = ay_dev.join(",");

      getList();

      break;
    case "export":
      // console.log(tabsName.value);
      if (tabsName.value == "detail") {
        proxy.$api.search_zdaqbj_detail_pdf(queryDetail).then((res) => {
          // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content], {
            type: "application/pdf;charset=utf-8",
          });
          var date =
            new Date().getFullYear() +
            "" +
            (new Date().getMonth() + 1) +
            "" +
            new Date().getDate() +
            "" +
            new Date().getHours() +
            "" +
            new Date().getMinutes() +
            "" +
            new Date().getSeconds() +
            "" +
            new Date().getMilliseconds();
          const fileName = tabsLabel.tabA + "_detail." + "pdf";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
          // console.log(res);
        });
      } else {
        ay_dev = [];
        obj.search.devids.forEach((it) => {
          if (it.nodetype == 2) {
            ay_dev.push(it.vDeviceID);
          }
        });
        query.devids = ay_dev.join(",");

        proxy.$api.search_zdaqbj_grid_pdf(query).then((res) => {
          // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content], {
            type: "application/pdf;charset=utf-8",
          });
          var date =
            new Date().getFullYear() +
            "" +
            (new Date().getMonth() + 1) +
            "" +
            new Date().getDate() +
            "" +
            new Date().getHours() +
            "" +
            new Date().getMinutes() +
            "" +
            new Date().getSeconds() +
            "" +
            new Date().getMilliseconds();
          const fileName = tabsLabel.tabA + "." + "pdf";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
          // console.log(res);
        });
      }
      // Object.assign(query, obj.search);

      break;
    case "download":
      if (tabsName.value == "detail") {
        proxy.$api.search_zdaqbj_detail_excel(queryDetail).then((res) => {
          // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          var date =
            new Date().getFullYear() +
            "" +
            (new Date().getMonth() + 1) +
            "" +
            new Date().getDate() +
            "" +
            new Date().getHours() +
            "" +
            new Date().getMinutes() +
            "" +
            new Date().getSeconds() +
            "" +
            new Date().getMilliseconds();
          const fileName = tabsLabel.tabA + "_detail." + "xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
          // console.log(res);
        });
      } else {
        ay_dev = [];
        obj.search.devids.forEach((it) => {
          if (it.nodetype == 2) {
            ay_dev.push(it.vDeviceID);
          }
        });
        query.devids = ay_dev.join(",");
        proxy.$api.search_zdaqbj_grid_excel(query).then((res) => {
          // 处理返回的文件流
          const content = res.data;
          const blob = new Blob([content], {
            type: "application/vnd.ms-excel;charset=utf-8",
          });
          var date =
            new Date().getFullYear() +
            "" +
            (new Date().getMonth() + 1) +
            "" +
            new Date().getDate() +
            "" +
            new Date().getHours() +
            "" +
            new Date().getMinutes() +
            "" +
            new Date().getSeconds() +
            "" +
            new Date().getMilliseconds();
          const fileName = tabsLabel.tabA + "." + "xlsx";
          if ("download" in document.createElement("a")) {
            // 非IE下载
            const elink = document.createElement("a");
            elink.download = fileName;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            document.body.removeChild(elink);
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName);
          }
          // console.log(res);
        });
      }

      // location.href = process.env.VUE_APP_BASE_URL + "Public/car.xlsx";
      break;
    case "groups":
      proxy.$api
        .search_getGroupList({ company_id: obj.company_id })
        .then((res) => {
          if (res.data.data.length > 0) {
            let ay = res.data.data.map((it) => {
              return {
                name: it.gName,
                value: it.id,
              };
            });
            searchCols.value[1].data = ay_companys;
          }
        });
      break;
    case "upload":
      if (obj.result == 1) {
        ElNotification({
          title: t("commKey.MSG_TITLE"),
          message: t("commKey.IMPORTSUCCESS"),
          duration: 2000,
        });
      } else {
        ElNotification({
          title: t("commKey.MSG_TITLE"),
          message: t("commKey.IMPORTFAIL"),
          duration: 2000,
        });
      }
      getList();
      break;
  }
};

//处理tabs
const tabsLabel = reactive(Tools.tabsLabel);
const tabsName = ref("list");
const tabsActiveNameChange = (val) => {
  tabsName.value = val;
};
///////////////////
const get_gps = (it, lng, lat, to) => {
  setTimeout(() => {
    proxy.$api
      .bdmap_get_gps_addr({
        lng: parseFloat(it[lng]),
        lat: parseFloat(it[lat]),
      })
      .then((res_addr) => {
        it[to] = res_addr.data.formatted_address;
      });
  }, 500);
};
const getDetail = () => {
  proxy.$api.search_zdaqbj_grid_detail(queryDetail).then((res) => {
    res.data.data.forEach((it) => {
      it.addr = "";
    });
    // console.log("ssss", res.data.data);
    tableDetailData.value = res.data.data;
    tableDetailData.value.forEach((it) => {
      get_gps(it, "pLongitude", "pLatitude", "addr");
    });
    totalDetail.value = res.data.pagination.totalRecords;
  });
};
const tableDetailCols = reactive(Tools.tableDetailCols);
const queryDetail = reactive({
  rp: 25,
  page: 1,
  devids: "",
  begintime: "",
  endtime: "",
});
const tableDetailData = ref([]);
const totalDetail = ref(0);
const handleCurrentChangeDetail = (val) => {
  queryDetail.page = val;
  getDetail();
};

const ErpDialogEvent = (obj) => {
  switch (obj.type) {
    case "close":
      isFileShow.value = false;
      break;
  }
  console.log(obj);
};
</script>

<style lang="scss" scoped></style>
